const BASE_URL = 'https://api.belibisgroup.co.id';
// const BASE_URL = 'http://localhost:8080';

function getAccessToken() {
  return localStorage.getItem('X-MD-SESSION');
}

function putAccessToken(accessToken) {
  localStorage.setItem('X-MD-SESSION', accessToken);
}

async function fetchWithToken(url, options = {}) {
  return fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${getAccessToken()}`,
    },
  });
}

async function login({ username, password }) {
  const response = await fetch(`${BASE_URL}/authenticate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  });

  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { errorMessage: responseJson.message, data: null };
}

async function getUserLogged() {
  const response = await fetchWithToken(`${BASE_URL}/authorize`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { errorMessage: responseJson.message, data: null };
}

async function getBooking(date, shipRoute, seatClass, shipId, subsidyStatus) {
  const response = await fetchWithToken(`${BASE_URL}/booking?date=${date}&shiproute=${shipRoute}&seatclass=${seatClass}&shipid=${shipId}&subsidy=${subsidyStatus}`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { statusCode: response.status, errorMessage: responseJson.message, data: null };
}

async function getBookingDetail(id) {
  const response = await fetchWithToken(`${BASE_URL}/booking/${id}`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { statusCode: response.status, errorMessage: responseJson.message, data: null };
}

async function deleteBooking(id, operatorId) {
  const response = await fetchWithToken(`${BASE_URL}/booking/${id}?operatorid=${operatorId}`, {
    method: 'DELETE',
  });

  const responseJson = await response.json();

  return { statusCode: response.status, message: responseJson.message };
}

async function putBooking(id, data) {
  const response = await fetchWithToken(`${BASE_URL}/booking/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const responseJson = await response.json();

  return { statusCode: response.status, message: responseJson.message };
}

async function postBooking(data) {
  const response = await fetchWithToken(`${BASE_URL}/booking`, {
    method: 'POST',
    body: JSON.stringify(data),
  });
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { message: responseJson.message, data: responseJson.data };
  }

  return { statusCode: response.status, message: responseJson.message, data: null };
}

async function getSeatList(category, date, shipRoute, shipId) {
  const response = await fetchWithToken(`${BASE_URL}/seatschema/${shipId}?shiproute=${shipRoute}&date=${date}&category=${category}`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { errorMessage: responseJson.message, data: null };
}

async function getPriceList(shipRoute) {
  const response = await fetchWithToken(`${BASE_URL}/pricelist/${shipRoute}`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { errorMessage: responseJson.message, data: null };
}

async function getTicketData(id) {
  const response = await fetchWithToken(`${BASE_URL}/tickets/${id}`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { errorMessage: responseJson.message, data: null };
}

async function getShipRouteList(userId, agencyId) {
  const response = await fetchWithToken(`${BASE_URL}/shiproutes?userid=${userId}&agencyid=${agencyId}`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { errorMessage: responseJson.message, data: null };
}

async function getShipList(routeId, userId) {
  const response = await fetchWithToken(`${BASE_URL}/ships/${routeId}?userid=${userId}`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { errorMessage: responseJson.message, data: null };
}

async function getDepartHours(shipRoute) {
  const response = await fetchWithToken(`${BASE_URL}/shiproutes/${shipRoute}/departhours`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { errorMessage: responseJson.message, data: null };
}

async function putTicketStatus(id, adminId) {
  const response = await fetchWithToken(`${BASE_URL}/booking/ticketScan/${id}?operatorid=${adminId}`, {
    method: 'PUT',
  });
  const responseJson = await response.json();

  return { statusCode: response.status, message: responseJson.message };
}

async function putNewPassword(id, data) {
  const response = await fetchWithToken(`${BASE_URL}/user-edit/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const responseJson = await response.json();

  return { statusCode: response.status, message: responseJson.message };
}

async function getBookingSummary(shipId) {
  const response = await fetchWithToken(`${BASE_URL}/statistic-summary?shipid=${shipId}`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { statusCode: response.status, errorMessage: responseJson.message, data: null };
}

async function getBookingStatistic(shipId, year, month) {
  const response = await fetchWithToken(`${BASE_URL}/statistic?shipid=${shipId}&year=${year}&month=${month}`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { statusCode: response.status, errorMessage: responseJson.message, data: null };
}

async function getDeletedBookingData(routeId, dateFrom, dateTo) {
  const response = await fetchWithToken(`${BASE_URL}/deleted-booking?routeid=${routeId}&from=${dateFrom}&to=${dateTo}`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { statusCode: response.status, errorMessage: responseJson.message, data: null };
}

async function getBookingRecapData(userId, period, date, month) {
  const dateParam = period === 'daily' ? `&date=${date}` : '';
  const monthParam = period === 'monthly' ? `&month=${month}` : '';

  const response = await fetchWithToken(`${BASE_URL}/recap-booking?userid=${userId}&period=${period}${dateParam}${monthParam}`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { statusCode: response.status, errorMessage: responseJson.message, data: null };
}

async function getRouteStatisticTable(period, date, month, year, page = 1) {
  const response = await fetchWithToken(`${BASE_URL}/route-statistic-table/${period}?date=${date}&month=${month}&year=${year}&page=${page}`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { statusCode: response.status, errorMessage: responseJson.message, data: null };
}

async function getRouteStatisticChart(period, date, month, year) {
  const response = await fetchWithToken(`${BASE_URL}/route-statistic-chart/${period}?date=${date}&month=${month}&year=${year}`);
  const responseJson = await response.json();

  if (responseJson.status === 'success') {
    return { data: responseJson.data };
  }

  return { statusCode: response.status, errorMessage: responseJson.message, data: null };
}

export
{
  login,
  getUserLogged,
  putAccessToken,
  getBooking,
  getBookingDetail,
  getSeatList,
  deleteBooking,
  putBooking,
  postBooking,
  getPriceList,
  getTicketData,
  getShipList,
  getShipRouteList,
  getDepartHours,
  putTicketStatus,
  putNewPassword,
  getBookingSummary,
  getBookingStatistic,
  getDeletedBookingData,
  getBookingRecapData,
  getRouteStatisticTable,
  getRouteStatisticChart,
};
