/* eslint-disable no-nested-ternary */
import 'react-datepicker/dist/react-datepicker.css';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSearchParams } from 'react-router-dom';
import { func } from 'prop-types';
import moment from 'moment';
import Swal from 'sweetalert2';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import LoadingSpinner from '../components/LoadingSpinner';
import useInput from '../hooks/useInput';
import { getRouteStatisticTable, getRouteStatisticChart, putAccessToken } from '../utils/api';

const localeCurrencyFormat = (number) => new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}).format(number);

function RouteStatistic({ setInitiate }) {
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedPeriod, setSelectedPeriod] = useInput(searchParams.get('period') || 'daily');
  const [selectedDate, setSelectedDate] = useInput(new Date());
  const [selectedMonth, setSelectedMonth] = useInput(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useInput(new Date().getFullYear());
  const [tableData, setTableData] = useState([]);
  const [tableMetaData, setTableMetaData] = useState(null);
  const [tableTotalPages, setTableTotalPages] = useState(null);
  const [tableCurrentPage, setTableCurrentPage] = useState(null);
  const [chartData, setChartData] = useState([]);

  const fetchData = async (getData, period, date, month, year, page) => {
    try {
      const { statusCode, errorMessage, data } = await getData(period, moment(date).format('YYYY-MM-D'), month, year, page);
      if (data) return data;
      if (statusCode === 401) {
        Swal.fire('Failed', 'Session anda telah habis, silahkan login kembali', 'error');
        putAccessToken('');
        setInitiate(true);
        return [];
      }
      Swal.fire('Failed', errorMessage, 'error');
      return [];
    } catch (error) {
      Swal.fire('Failed', 'An error occurred while fetching data.', 'error');
      return [];
    }
  };

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);

      const routeTableData = await fetchData(
        getRouteStatisticTable, selectedPeriod, selectedDate, selectedMonth, selectedYear,
      );
      setTableData(routeTableData.route_table);
      setTableMetaData(routeTableData.route_table_meta);
      setTableTotalPages(routeTableData.pagination.total_pages);
      setTableCurrentPage(routeTableData.pagination.current_page);

      const routeChartData = await fetchData(
        getRouteStatisticChart, selectedPeriod, selectedDate, selectedMonth, selectedYear,
      );
      setChartData(routeChartData.route_chart);

      setLoading(false);

      searchParams.set('period', selectedPeriod);
      setSearchParams(searchParams, { replace: true });
    };

    loadData();
  }, [selectedPeriod, selectedDate, selectedMonth, selectedYear]);

  useEffect(() => {
    const loadData = async () => {
      if (tableCurrentPage) {
        setLoading(true);

        const routeTableData = await fetchData(
          getRouteStatisticTable,
          selectedPeriod,
          selectedDate,
          selectedMonth,
          selectedYear,
          tableCurrentPage,
        );
        setTableData(routeTableData.route_table);

        setLoading(false);
      }
    };

    loadData();
  }, [tableCurrentPage]);

  const visiblePageCount = 15;
  const getPaginationRange = () => {
    let startPage = Math.max(1, tableCurrentPage - Math.floor(visiblePageCount / 2));
    let endPage = Math.min(tableTotalPages, tableCurrentPage + Math.floor(visiblePageCount / 2));

    if (tableCurrentPage <= Math.floor(visiblePageCount / 2)) {
      endPage = Math.min(tableTotalPages, visiblePageCount);
    }
    if (tableCurrentPage >= tableTotalPages - Math.floor(visiblePageCount / 2)) {
      startPage = Math.max(1, tableTotalPages - visiblePageCount + 1);
    }

    return { startPage, endPage };
  };

  const handleTablePageChange = (pageNumber) => {
    if (pageNumber !== tableCurrentPage) {
      setTableCurrentPage(pageNumber);
    }
  };

  const chartCustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="card">
          <div className="card-body">
            <h6 className="card-title">{payload[0].payload.ship_route}</h6>
            <h6 className="card-subtitle mb-2 text-body-secondary">{payload[0].payload.ship_name}</h6>
            <small className="card-text">
              <span style={{ color: '#82ca9d' }}>
                {`Jumlah Ekonomi: ${payload[0].payload.eco_count}`}
                <br />
                {`Harga Ekonomi: ${localeCurrencyFormat(payload[0].payload.eco_total)}`}
                <br />
              </span>
              <span style={{ color: '#8884d8' }}>
                {`Jumlah VIP: ${payload[0].payload.vip_count}`}
                <br />
                {`Harga VIP: ${localeCurrencyFormat(payload[0].payload.vip_total)}`}
                <br />
              </span>
              {`Total Jumlah: ${payload[0].payload.total_ticket}`}
              <br />
              {`Total Harga: ${localeCurrencyFormat(payload[0].payload.total_price)}`}
            </small>
          </div>
        </div>
      );
    }
    return null;
  };

  const { startPage, endPage } = getPaginationRange();

  return (
    <section>
      <div className="row g-3">
        <div className="col-lg-2">
          <select className="form-select" onChange={setSelectedPeriod} value={selectedPeriod}>
            <option value="daily">Daily</option>
            <option value="monthly">Monthly</option>
            <option value="yearly">Yearly</option>
          </select>
        </div>

        {
          selectedPeriod === 'daily' && (
            <>
              <div className="col-auto">
                <label className="col-form-label ps-4" htmlFor="dateInput">Tanggal:</label>
              </div>
              <div className="col-lg-2">
                <DatePicker
                  id="dateInput"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selected={selectedDate}
                  onChange={setSelectedDate}
                />
              </div>
            </>
          )
        }

        {
          selectedPeriod === 'monthly' && (
            <div className="col-lg-2">
              <select className="form-select" value={selectedMonth} onChange={setSelectedMonth}>
                <option value="1">Januari</option>
                <option value="2">Februari</option>
                <option value="3">Maret</option>
                <option value="4">April</option>
                <option value="5">Mei</option>
                <option value="6">Juni</option>
                <option value="7">Juli</option>
                <option value="8">Agustus</option>
                <option value="9">September</option>
                <option value="10">Oktober</option>
                <option value="11">November</option>
                <option value="12">Desember</option>
              </select>
            </div>
          )
        }

        {
          (selectedPeriod === 'monthly' || selectedPeriod === 'yearly') && (
            <div className="col-lg-2">
              <select className="form-select" value={selectedYear} onChange={setSelectedYear}>
                <option value="2025">2025</option>
                <option value="2024">2024</option>
                <option value="2023">2023</option>
              </select>
            </div>
          )
        }
      </div>

      <div className="col-12">
        <div className="table-responsive pt-1 pb-1">
          <h6 className="text-lg-end"><small>{tableMetaData && `Data: ${tableMetaData.description}`}</small></h6>
          <table className="table table-striped table-hover table-bordered">
            <thead>
              <tr className="text-nowrap">
                <th className="py-3" scope="col">Tanggal</th>
                <th className="py-3" scope="col">Rute</th>
                <th className="py-3" scope="col">Kapal</th>
                <th className="py-3" scope="col">Jumlah VIP</th>
                <th className="py-3" scope="col">Harga VIP</th>
                <th className="py-3" scope="col">Jumlah Ekonomi</th>
                <th className="py-3" scope="col">Harga Ekonomi</th>
                <th className="py-3" scope="col">Total Jumlah</th>
                <th className="py-3" scope="col">Total Harga</th>
              </tr>
            </thead>
            <tbody className="table-group-divider">
              {
                loading ? (
                  <tr>
                    <td colSpan="11" className="text-center">
                      <LoadingSpinner />
                    </td>
                  </tr>
                ) : tableData.length === 0
                  ? (
                    <tr>
                      <td colSpan="9">
                        <p className="text-center text-warning m-0">Data tidak tersedia</p>
                      </td>
                    </tr>
                  ) : tableData.map((row) => (
                    <tr className="text-nowrap" key={row.id}>
                      <td>{row.date}</td>
                      <td>{`${row.ship_route}`}</td>
                      <td>{row.ship_name}</td>
                      <td>{row.vip_count}</td>
                      <td>{localeCurrencyFormat(row.vip_total)}</td>
                      <td>{row.eco_count}</td>
                      <td>{localeCurrencyFormat(row.eco_total)}</td>
                      <td>{row.total_ticket}</td>
                      <td>{localeCurrencyFormat(row.total_price)}</td>
                    </tr>
                  ))
              }
            </tbody>
            <tfoot>
              <tr>
                <th colSpan={7}>Total Keseluruhan</th>
                <th>{tableMetaData && tableMetaData.total.total_ticket }</th>
                <th>
                  { tableMetaData && localeCurrencyFormat(tableMetaData.total.total_price) }
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      {
        tableTotalPages ? (
          <div className="col-12">
            <nav className="pb-3">
              <ul className="d-flex pagination">
                <li className={`page-item ${tableCurrentPage === 1 && 'disabled'}`}>
                  <a href="#page" onClick={() => handleTablePageChange(tableCurrentPage - 1)} className="page-link">Previous</a>
                </li>
                {
                  Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                    <li
                      key={startPage + index}
                      className={`page-item ${startPage + index === tableCurrentPage && 'active'} ${loading && 'disabled'}`}
                    >
                      <a href="#page" onClick={() => handleTablePageChange(startPage + index)} className="page-link">{startPage + index}</a>
                    </li>
                  ))
                }
                <li className={`page-item ${tableCurrentPage === tableTotalPages && 'disabled'}`}>
                  <a href="#page" onClick={() => handleTablePageChange(tableCurrentPage + 1)} className="page-link">Next</a>
                </li>
              </ul>
            </nav>
          </div>
        ) : null
      }

      <div className="col-12">
        {
          loading
            ? <LoadingSpinner />
            : chartData.length > 0 && (
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  data={chartData}
                  margin={{
                    top: 5,
                    right: 30,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis tick={{ fontSize: 8 }} dataKey="ship_name" />
                  <XAxis
                    dataKey="ship_route"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fontSize: 10 }}
                    xAxisId={1}
                  />
                  <YAxis />
                  <Tooltip content={chartCustomTooltip} />
                  <Legend />
                  <Bar dataKey="vip_count" name="jumlah vip" stackId="a" fill="#8884d8" />
                  <Bar dataKey="eco_count" name="jumlah ekonomi" stackId="a" fill="#82ca9d" />
                </BarChart>
              </ResponsiveContainer>
            )
        }
      </div>
    </section>
  );
}

RouteStatistic.propTypes = {
  setInitiate: func.isRequired,
};

export default RouteStatistic;
