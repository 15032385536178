import React, { useContext } from 'react';
import { bool } from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import {
  FaHome,
  FaTicketAlt,
  FaDatabase,
  FaQrcode,
  FaUser,
  FaUserEdit,
  FaChartBar,
} from 'react-icons/fa';
import AuthedContext from '../contexts/AuthedContext';

function Sidebar({ showSidebar }) {
  const { pathname } = useLocation();
  const { authedUser } = useContext(AuthedContext);

  return (
    <nav className={`col-2 bg-white-light sidebar shadow-lg ${showSidebar ? 'toggled' : ''} d-print-none`}>
      <div className="sidebar-heading text-center py-4 primary-text fw-bold text-uppercase border-bottom">
        <Link to="/" className="text-decoration-none">
          <img className="d-lg-none" src="images/logo32x.png" alt="logo" />
          <img className="d-none d-lg-inline" src="images/belibis-logo.png" width="100" alt="logo" />
        </Link>
      </div>
      <ul className="nav flex-column p-2">
        {
          authedUser.role !== 'management' && (
            <>
              <li className="m-2">
                <Link className={`list-group-item-action text-decoration-none ${pathname === '/dashboard' ? 'text-primary' : ''}`} to="/dashboard">
                  <FaHome className="fs-5 me-lg-2" />
                  <span className="d-none d-lg-inline">Dashboard</span>
                </Link>
              </li>
              <li className="m-2">
                <Link className={`list-group-item-action text-decoration-none ${pathname === '/bookingticket' ? 'text-primary' : ''}`} to="/bookingticket">
                  <FaTicketAlt className="fs-5 me-lg-2" />
                  <span className="d-none d-lg-inline">Booking Ticket</span>
                </Link>
              </li>
              <li className="m-2">
                <Link className={`list-group-item-action text-decoration-none ${pathname === '/bookingdata' ? 'text-primary' : ''}`} to="/bookingdata">
                  <FaDatabase className="fs-5 me-lg-2" />
                  <span className="d-none d-lg-inline">Booking Data</span>
                </Link>
              </li>
            </>
          )
        }
        <li className="m-2">
          <Link className={`list-group-item-action text-decoration-none ${pathname === '/user-profile' ? 'text-primary' : ''}`} to="/user-profile">
            <FaUserEdit className="fs-5 me-lg-2" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="User Profile" data-bs-trigger="click" />
            <span className="d-none d-lg-inline">User Profile</span>
          </Link>
        </li>
        {
          ['master', 'comprador1', 'comprador2'].includes(authedUser.role) && (
            <li className="m-2">
              <Link className={`list-group-item-action text-decoration-none ${pathname === '/scanticket' ? 'text-primary' : ''}`} to="/scanticket">
                <FaQrcode className="fs-5 me-lg-2" />
                <span className="d-none d-lg-inline">Scan Ticket</span>
              </Link>
            </li>
          )
        }
        {
          ['comprador1', 'comprador2'].includes(authedUser.role) && (
            <li className="m-2">
              <Link className={`list-group-item-action text-decoration-none ${pathname === '/data-recap' ? 'text-primary' : ''}`} to="/data-recap">
                <FaUser className="fs-5 me-lg-2" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Data Recap" data-bs-trigger="click" />
                <span className="d-none d-lg-inline">Rekap Penjualan</span>
              </Link>
            </li>
          )
        }
        {
          ['master', 'management'].includes(authedUser.role) && (
            <>
              <li className="m-2">
                <Link className={`list-group-item-action text-decoration-none ${pathname === '/route-statistic' ? 'text-primary' : ''}`} to="/route-statistic">
                  <FaChartBar className="fs-5 me-lg-2" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Route Statistic" data-bs-trigger="click" />
                  <span className="d-none d-lg-inline">Route Statistic</span>
                </Link>
              </li>
              <li className="m-2">
                <Link className={`list-group-item-action text-decoration-none ${pathname === '/data-statistic' ? 'text-primary' : ''}`} to="/data-statistic">
                  <FaChartBar className="fs-5 me-lg-2" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Data Statistic" data-bs-trigger="click" />
                  <span className="d-none d-lg-inline">Data Statistic</span>
                </Link>
              </li>
            </>
          )
        }
        {
          ['master'].includes(authedUser.role) && (
            <li className="m-2">
              <Link className={`list-group-item-action text-decoration-none ${pathname === '/deleted-data' ? 'text-primary' : ''}`} to="/deleted-data">
                <FaDatabase className="fs-5 me-lg-2" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Deleted Data" data-bs-trigger="click" />
                <span className="d-none d-lg-inline">Deleted Data</span>
              </Link>
            </li>
          )
        }
      </ul>
    </nav>
  );
}

Sidebar.propTypes = {
  showSidebar: bool.isRequired,
};

export default Sidebar;
