/* eslint-disable max-len */
import React, { useState, useContext } from 'react';
import { putAccessToken } from '../utils/api';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import AuthedContext from '../contexts/AuthedContext';

// eslint-disable-next-line react/prop-types
function AdminPage({ children }) {
  const { setAuthedUser } = useContext(AuthedContext);
  const [showSidebar, setShowSidebar] = useState(true);

  const logoutHandler = () => {
    putAccessToken('');
    setAuthedUser(null);
    window.location.reload(false);
  };

  return (
    <div className="d-flex">
      <Sidebar showSidebar={showSidebar} />
      <div className="container-fluid">
        <div className="row">
          <Navbar setShowSidebar={setShowSidebar} />
          <main className="container overflow-auto py-4">
            {children}
          </main>
        </div>
      </div>

      <div className="modal fade" id="logoutModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Logout</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              Are you sure want to logout ?
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" onClick={logoutHandler} className="btn btn-danger">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
